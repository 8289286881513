import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import SpinnerBox from '../../../componentsV2/DesignSystem/SpinnerBox';
import TabNavigation from '../../../componentsV2/DesignSystem/TabNavigation';
import { Heading } from '../../../componentsV2/DesignSystem/Typography';
import useFetchUserFeatureFlagFindByKey, {
  FeatureKey,
} from '../../../graphql/queries/useFetchUserFeatureFlagFindByKey';
import useAccount from '../../../hooks/useAccount';
import useAuth from '../../../hooks/useAuth';
import Forbidden from '../../Forbidden';
import ManageInviteLinks from './ManageInviteLinks';
import Overview from './Overview';
import RewardsPlugin from './RewardsPlugin';
import ShareLinks from './ShareLinks';
import ShareWidgets from './ShareWidgets';
import styles from './styles.module.scss';

export default function ShareCenter(): JSX.Element {
  const { path, url } = useRouteMatch();
  const { getAccount } = useAccount();
  const { denyEditorAccess, getMe } = useAuth();

  const { data, loading, refetch } = useFetchUserFeatureFlagFindByKey(getAccount.accountId, FeatureKey.ShareCenter);

  const tabItems = [
    {
      link: `${url}/share-links`,
      icon: 'share',
      name: 'Share Links',
    },
    {
      link: `${url}/manage-invitelinks`,
      icon: 'format_list_bulleted',
      name: 'Manage InviteLinks',
    },
    {
      link: `${url}/display-widget`,
      icon: 'widgets',
      name: 'Display Widget',
    },
    {
      link: `${url}/rewards-plugin`,
      icon: 'featured_seasonal_and_gifts',
      name: 'Reward Plugin',
      hide: !(getMe.superAdmin || (!getMe.superAdmin && ['11688'].includes(getAccount.accountId.toString()))),
    },
  ];

  if (denyEditorAccess(getAccount.accountId)) {
    return <Forbidden title="Share your promotions" />;
  }

  return (
    <div className={styles.wrapper}>
      <Heading size={2}>Share your promotions</Heading>
      {loading ? (
        <div className={styles['loading']}>
          <SpinnerBox />
        </div>
      ) : (
        <>
          {data?.id ? (
            <>
              <TabNavigation tabItems={tabItems} />
              <section>
                <Switch>
                  <Route exact path={`${path}/share-links`} component={ShareLinks} />
                  <Route exact path={`${path}/manage-invitelinks`} component={ManageInviteLinks} />
                  <Route path={`${path}/display-widget`} component={ShareWidgets} />
                  <Route path={`${path}/rewards-plugin`} component={RewardsPlugin} />
                  <Redirect path={url} to={`${url}/share-links`} />
                </Switch>
              </section>
            </>
          ) : (
            <Overview refetch={refetch} />
          )}
        </>
      )}
    </div>
  );
}
