import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { REACT_APP_MONOLITH_V2 } from '../../config/Env';
import { NavigationSidebarLinks } from '../../config/NavigationLinksData';
import useAuth from '../../hooks/useAuth';
import usePostHog from '../../hooks/usePostHog';
import styleModuleClasses from '../../utils/styleModuleClasses';
import { Button } from '../DesignSystem/Inputs/Buttons/ButtonAction';
import ButtonNavigation from '../DesignSystem/Inputs/Buttons/ButtonNavigation';
import LinkSupportHubspot from './LinkSupportHubspot';
import { NavigationProps } from './interface';
import styles from './styles.module.scss';

export default function NavigationSidebar({ collapsed }: NavigationProps): JSX.Element {
  const { getMe } = useAuth();
  const location = useLocation();
  const { resetPostHog } = usePostHog();

  const logout = () => {
    resetPostHog();
    window.location.assign(`${REACT_APP_MONOLITH_V2}/signout`);
  };

  const navigationLinks = useMemo(() => {
    return NavigationSidebarLinks.filter((link) => !link.superAdmin || (link.superAdmin && getMe.superAdmin));
  }, [NavigationSidebarLinks, getMe.superAdmin]);

  return (
    <div
      className={styleModuleClasses(styles, 'hidden-down-md', 'sidebar-wrapper', {
        collapsed,
      })}
    >
      <ul>
        {navigationLinks.map((item) => (
          <li
            key={`options-sidebar-${item.icon}`}
            className={styleModuleClasses(styles, {
              hr: !item.url,
              active:
                item.url !== null &&
                location.pathname !== '/promotions/create' &&
                location.pathname.indexOf(item.url) > -1,
            })}
          >
            {item.url !== null && (
              <ButtonNavigation
                to={item.url}
                {...(item.icon && { firstIcon: { children: item.icon } })}
                variant="tertiary"
                text={{ color: 'dark', align: 'start' }}
              >
                {item.title}
              </ButtonNavigation>
            )}
          </li>
        ))}
      </ul>
      <ul className={styleModuleClasses(styles, 'actions-wrapper')}>
        <li
          className={styleModuleClasses(styles, {
            hr: false,
            active: location.pathname.indexOf('settings') > -1,
          })}
        >
          <ButtonNavigation
            to={'/settings/your-details'}
            firstIcon={{ children: 'settings' }}
            variant="tertiary"
            text={{ color: 'dark', align: 'start' }}
          >
            Settings
          </ButtonNavigation>
        </li>

        <LinkSupportHubspot />
        <li
          className={styleModuleClasses(styles, {
            hr: false,
          })}
        >
          <Button
            firstIcon={{ children: 'exit_to_app', style: { transform: 'rotate(180deg)' } }}
            variant="tertiary"
            text={{ color: 'dark', align: 'start' }}
            onClick={logout}
          >
            Logout
          </Button>
        </li>
      </ul>
    </div>
  );
}
